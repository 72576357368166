<template>
  <div>
    <v-row class="mb-4">
      <v-col class="titleWidth">
        <h1 class="text-h1 text-no-wrap">
          簽核管理｜
          <span style="color: #0099cc"
            >{{ type_title }} {{ year }}-{{ no }}</span
          >
        </h1>
      </v-col>
      <v-col class="d-flex align-center justify-end">
        <span class="grey2--text mr-2">簽核角色：副總稽核</span>
        <v-btn to="/home/to-sign-off" depressed class="mr-2" color="#E9E9E9"
          >返回首頁</v-btn
        >
      </v-col>
    </v-row>
    <div class="d-flex align-end mb-3">
      <span class="text-h3 mb-0">待簽核列表</span>
      <v-btn
        v-show="type == 'unitOrientation'"
        @click="groupSignStatusView = true"
        outlined
        class="inputWhiteBg ml-5"
        color="primary"
        dense
        >單位簽核狀態一覽</v-btn
      >
      <div v-show="type == 'unitOrientation'" class="grey--text text--darken-1">
        <span class="subtitle-1 mx-5">未交付 11</span>
        <span class="subtitle-1 mr-5">
          <span class="orange--text">待簽核</span> 36</span
        >
        <span class="subtitle-1 mr-5">
          <span class="success--text">已簽核</span> 3</span
        >
        <span class="subtitle-1 mr-5">總計 50</span>
      </div>
      <div class="ml-auto">
        <v-btn
          @click="disallowDialog = true"
          :disabled="selected.length == 0"
          depressed
          color="error"
          class="mr-2"
          >駁回</v-btn
        >
        <v-btn
          @click="approve"
          :disabled="selected.length == 0"
          class="mr-2"
          depressed
          color="primary"
          >核可</v-btn
        >
        <v-btn :disabled="selected.length == 0" depressed color="primary"
          >匯出</v-btn
        >
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="toSignOffList"
      hide-default-footer
      show-select
      v-model="selected"
      class="material-table"
    >
      <template #[`item.review`]="{ item }">
        <v-chip
          v-if="item.review == true"
          @click="toggleReview(item)"
          color="success"
          style="color: white"
          small
          >已審閱</v-chip
        >
        <v-chip v-else small @click="toggleReview(item)">未審閱</v-chip>
      </template>
      <template #[`item.updatedAt`]="{ item }">
        {{ dateFormate(item.updatedAt) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-icon class="pointer" @click="goDetail(item)">mdi-eye</v-icon>
      </template>
    </v-data-table>
    <material-pagination></material-pagination>
    <!-- ====== Dialog ====== -->
    <!-- 核可版本 -->
    <material-dialog
      title="核可版本"
      v-model="approveVersionDialog"
      max-width="420"
    >
      <p>
        請問你是否要核可並送簽以下
        <span class="error--text">{{ selected.length }} </span> 個版本?
      </p>
      <chip-grey v-for="item in selected" :key="item.id">
        {{ `${year}-${no} ${item.group}` }}
      </chip-grey>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="approveVersionDialog = false">取消</btn-cancel>
        <v-btn color="primary" depressed @click="approveVersionDialog = false">
          核可並送簽
        </v-btn>
      </template>
    </material-dialog>
    <!-- 駁回版本 -->
    <material-dialog title="駁回版本" v-model="disallowDialog" max-width="420">
      <p>
        請問你是否要駁回以下
        <span class="error--text">{{ selected.length }} </span> 個版本?
      </p>
      <div v-for="item in selected" :key="item.id">
        <chip-grey class="mb-3">
          {{ `${year}-${no} ${item.group}` }}
        </chip-grey>
        <v-textarea
          v-model="item.desc"
          outlined
          label="請填寫駁回原因"
          single-line
          rows="2"
          counter="50"
          :rules="rules"
          auto-grow
        ></v-textarea>
      </div>
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="closeDisallow">取消</btn-cancel>
        <v-btn color="error" depressed @click="disallowSubmit">
          駁回版本
        </v-btn>
      </template>
    </material-dialog>
    <!-- 單位簽核狀態一覽 -->
    <group-sign-status-dialog
      :dialog="groupSignStatusView"
      @close="groupSignStatusView = false"
    ></group-sign-status-dialog>
  </div>
</template>
<script>
/* TODO:
1. 駁回 dialog
2. 單位簽核狀態一覽 dialog
3. browse pages
4. 直接匯出
*/
import { sync } from "vuex-pathify";
import { dateFormateMixin, versionMixin } from "@/helper";
import GroupSignStatusDialog from "./components/GroupSignStatusDialog.vue";
export default {
  mixins: [dateFormateMixin, versionMixin],
  components: { GroupSignStatusDialog },
  data() {
    return {
      type_title: "單位風險導向",
      year: "2022",
      no: "02",
      selected: [],
      headers: [
        {
          text: "單位",
          align: "start",
          sortable: false,
          value: "group",
        },
        {
          text: "審閱狀態",
          align: "start",
          sortable: false,
          width: 150,
          value: "review",
        },
        {
          text: "送簽人",
          align: "start",
          sortable: false,
          width: 150,
          value: "sender",
        },
        {
          text: "更新時間",
          align: "start",
          value: "updatedAt",
        },
        {
          text: "操作",
          align: "start",
          sortable: false,
          width: 100,
          value: "actions",
        },
      ],
      toSignOffList: [
        {
          id: 1,
          group: "車險商品部",
          review: false,
          sender: "張仲謀",
          updatedAt: "2022-01-04 10:36:39",
        },
        {
          id: 2,
          group: "車險商品部",
          review: false,
          sender: "張仲謀",
          updatedAt: "2022-01-04 10:36:39",
        },
      ],
      // === Dialogs ===
      exportDialog: false,
      approveVersionDialog: false,
      disallowDialog: false,
      rules: [
        (v) => {
          if (!v) return true;
          else return v.length <= 50 || "字數上限為50字";
        },
      ],
      groupSignStatusView: false,
    };
  },
  computed: {
    ...sync("global", ["alert"]),
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    checkParams() {
      console.log(this.$route.params);
      let p = Object.values(this.$route.params);
      let hasfalsy = p.find((el) => ["null", "undefined"].includes(el));
      console.log({ hasfalsy });
      if (hasfalsy) {
        this.$router.push("/to-sign-off");
        this.alert = {
          open: true,
          content: "找不到相關頁面。",
        };
        return;
      }
      const { type, year, no } = this.$route.params;
      let title = "";
      switch (type) {
        case "unitOrientation":
          title = "單位風險導向";
          break;
        case "riskType":
          title = "風險類型與項目";
          break;
        case "allocation":
          title = "配分設定";
          break;
      }
      this.type_title = title;
      this.year = year;
      this.no = this.versionText(no);
    },
    toggleReview(item) {
      item.review = !item.review;
    },
    approve() {
      let noReview = this.selected.find((el) => !el.review);
      console.log({ noReview });
      if (!noReview) this.approveVersionDialog = true;
      else
        this.alert = {
          open: true,
          content: "已勾選之版本清單中尚有未審閱之版本",
          type: "approveAlert",
          onOk: () => (this.approveVersionDialog = true),
          onCancel: () => (this.alert = { open: false }),
        };
    },
    closeDisallow() {
      this.selected.forEach((el) => {
        if (el.desc) delete el.desc;
      });
      this.disallowDialog = false;
    },
    disallowSubmit() {
      let err = this.selected.find((el) => {
        if (el.desc) {
          return el.desc.length > 50;
        }
      });
      if (err !== undefined) {
        this.alert = {
          open: true,
          content: "駁回原因字數上限為50字，請檢查字數是否超過上限。",
        };
        return;
      }
      // TODO: Submit API
      this.closeDisallow();
    },
    goDetail(item) {
      // this.$router.push({
      //   path: `/sign-off-browse`,
      //   query: { type: this.type, id: item.id },
      // });
      // let pageName = "";
      switch (this.type) {
        case "unitOrientation":
          // pageName = "unit-field";
          this.$router.push(
            `/sign-off-browse/${this.type}/unit-field/3/signOff`
          );

          break;
        case "riskType":
          // pageName = "risk-type";
          this.$router.push(
            `/sign-off-browse/${this.type}/risk-type/1/signOff`
          );

          break;
        case "allocation":
          // pageName = "allocation";
          this.$router.push(
            `/sign-off-browse/${this.type}/allocation/1/signOff`
          );

          break;
      }
    },
  },
  mounted() {
    // this.setTextareaHeight();
    this.checkParams();
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/main.scss";
.titleWidth {
  max-width: 550px;
  min-width: 500px;
}
.depSelect {
  max-width: 300px;
}
.textarea {
  width: 60%;
  &:deep(.v-input__control) {
    min-width: 200px;
    background: #fff;
  }
}
.importArea {
  @include flexCenter;
  @include boxSize(100%, 120px);
  border: 2px dashed #cacaca;
  transition: all 0.2s ease;
  &:hover {
    background-color: rgba(#000, 0.05);
  }
}
</style>
