<template>
  <material-dialog title="單位簽核狀態一覽" v-model="dialog" max-width="650">
    <div class="d-flex">
      <div class="status_column">
        <div class="status_title">
          <span>未交付</span>
          <span> 11</span>
        </div>
        <!-- <div class="status_content">
          <p v-for="(item, idx) in statusData[0].list" :key="idx">{{ item }}</p>
        </div> -->
      </div>
      <div class="status_column">
        <div class="status_title">
          <span class="orange--text">待簽核</span>
          <span> 36</span>
        </div>
        <!-- <div class="status_content"></div> -->
      </div>
      <div class="status_column">
        <div class="status_title">
          <span class="success--text">已簽核</span>
          <span> 3</span>
        </div>
        <!-- <div class="status_content"></div> -->
      </div>
    </div>
    <div class="status_scrollable_p">
      <div class="d-flex status_scrollable_c">
        <div class="status_column" v-for="i in statusData" :key="i.status">
          <div class="status_content">
            <p v-for="(child, idx) in statusData[i.status].list" :key="idx">
              {{ child }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" depressed @click="$emit('close')"> 關閉 </v-btn>
    </template>
  </material-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //   --- temp ---
      statusData: [
        {
          status: 0,
          list: [
            "分公司",
            "通路推展部",
            "個人保險營業部",
            "電子商務部",
            "營業管理部",
            "汽車保險商品部",
            "個人保險商品部",
            "客戶服務管理部",
            "企業保險理賠部",
            "客戶服務",
            "數位服務部",
          ],
        },
        {
          status: 1,
          list: [
            "企業保險營業",
            "損害防阻暨勞工安全衛生部",
            "火災保險商品部",
            "工程暨海上保險商品部",
            "再保險部",
            "核保中心",
            "投資部",
            "人力資源部",
            "精算部",
            "國外部",
            "風險管理部",
            "總務行政部",
          ],
        },
        { status: 2, list: ["法令遵循部", "法務部", "國際保險業務分公司"] },
      ],
      // ---------------
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/main.scss";
.status {
  &_column {
    width: 196px;
    // height: 300px;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  &_title {
    background-color: $grey5;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-size: 14px;

    span {
      &:first-child {
        font-weight: bold;
      }
    }
  }
  &_content {
    p {
      font-size: 14px;
    }
    padding: 8px;
  }
  &_scrollable_p {
    overflow: hidden;
    height: 400px;
  }
  &_scrollable_c {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 17px;
    box-sizing: content-box;
    height: 100%;
    width: 100%;
  }
}
</style>
